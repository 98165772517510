
<template>
    <header>
        <div id="Header_Main">
            <div class="Header_navBar">
                <div class="menuBar">
                    <h2>Document Management</h2>
                </div>

                <div id="v-model-select" class="menuBar_Category">
                    <div id="v-model-select">
                        <select @change="Category($event)">
                            <option value="">Please Select Category</option>
                            <option value="Category1">Category 1</option>
                            <option value="Category2">Category 2</option>
                            <option value="Category3">Category 3</option>
                            <option value="Category3">Category 4</option>
                        </select>

                    </div>
                </div>

                <ul class="Docs_dropdown">
                    <li><router-link to="#">Menu</router-link></li>
                    <li><router-link to="/Project">Project</router-link></li>
                    <li><router-link to="/Docs">Document</router-link></li>
                    <li><router-link to="/#">Menu</router-link></li>
                    <li><router-link to="#">Menu</router-link></li>
                </ul>

            </div>
        </div>
    </header>


    <div id="Reports_Main">
        <div class="Reports_Div">
            <div class="Reports_Category_Div">
                <h4>Documents</h4>
                <ul>
                    <li><router-link to="/docs">Folders</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reviews">Reviews</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/transmittals">Transmittals</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/issues">Issues</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reports">Reports</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                </ul>
            </div>

            <div class="Reports_List_Div">
                <div class="Reports_List_Title">
                    <h2>Reports</h2>
                    <ul>
                        <li>Reports log</li>
                        <li>Templates</li>
                    </ul>
                </div>
            </div>


            <div class="Reports_folders_List_Div">

                <div id="v-model-select" class="Reports_SubCategory_List">
                    <select @change="SubCategory($event)">
                        <option value="">Please Select Category</option>
                        <option value="Category1">Assignde items by company</option>
                        <option value="Category2">Issue Detail</option>
                        <option value="Category3">Issue status summary</option>
                        <option value="Category3">Issue summary</option>
                    </select>

                </div>

                <table class="Reports_folders_Sort">
                    <tr class="">
                        <th>Title</th>
                        <th>Type</th>
                        <th>Run at</th>
                        <th>Created by</th>
                        <th>Source Template</th>
                        <th>Schedule</th>
                        <th>Recipients</th>
                    </tr>

                    <tr v-for="item in items" :key="item.title">
                        <td><span v-html="item.Title"></span></td>
                        <td><span v-html="item.Type"></span></td>
                        <td><span v-html="item.Runat"></span></td>
                        <td><span v-html="item.Createdby"></span></td>
                        <td><span v-html="item.SourceTemplate"></span></td>
                        <td><span v-html="item.Schedule"></span></td>
                        <td><span v-html="item.Recipients"></span></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<style>
    @import "../assets/css/Docs_reports.css";
    @import "../assets/css/reset.css";
</style>


<script>

    export default {
        Subcategory(envet) {
            console.log(event.target.value); //value�� ���
        },

        data() {
            return {

                items: [    // folders_list_div ����
                    {
                        "Title": "Issue Detail",
                        "Type": "Issue Detail",
                        "Runat": "2022.10.24 pm 13:30",
                        "Createdby": "Gildong Hong",
                        "SourceTemplate": "",
                        "Schedule": "2022.10.25 ~ 2022.11.02",
                        "Recipients": "",
                    },

                    {
                        "Title": "Issue Detail",
                        "Type": "Issue Detail",
                        "Runat": "2022.10.24 pm 13:30",
                        "Createdby": "Gildong Hong",
                        "SourceTemplate": "",
                        "Schedule": "2022.10.25 ~ 2022.11.02",
                        "Recipients": "",
                    },

                    {
                        "Title": "Issue Detail",
                        "Type": "Issue Detail",
                        "Runat": "2022.10.24 pm 13:30",
                        "Createdby": "Gildong Hong",
                        "SourceTemplate": "",
                        "Schedule": "2022.10.25 ~ 2022.11.02",
                        "Recipients": "",
                    },

                    {
                        "Title": "Issue Detail",
                        "Type": "Issue Detail",
                        "Runat": "2022.10.24 pm 13:30",
                        "Createdby": "Gildong Hong",
                        "SourceTemplate": "",
                        "Schedule": "2022.10.25 ~ 2022.11.02",
                        "Recipients": "",
                    },

                    {
                        "Title": "Issue Detail",
                        "Type": "Issue Detail",
                        "Runat": "2022.10.24 pm 13:30",
                        "Createdby": "Gildong Hong",
                        "SourceTemplate": "",
                        "Schedule": "2022.10.25 ~ 2022.11.02",
                        "Recipients": "",
                    },

                    {
                        "Title": "Issue Detail",
                        "Type": "Issue Detail",
                        "Runat": "2022.10.24 pm 13:30",
                        "Createdby": "Gildong Hong",
                        "SourceTemplate": "",
                        "Schedule": "2022.10.25 ~ 2022.11.02",
                        "Recipients": "",
                    },

                    {
                        "Title": "Issue Detail",
                        "Type": "Issue Detail",
                        "Runat": "2022.10.24 pm 13:30",
                        "Createdby": "Gildong Hong",
                        "SourceTemplate": "",
                        "Schedule": "2022.10.25 ~ 2022.11.02",
                        "Recipients": "",
                    },

                ],
            }
        }
    }
</script>
