
<template>
    <header>
        <div id="Header_Main">
            <div class="Header_navBar">
                <div class="menuBar">
                    <h2>Document Management</h2>
                </div>

                <div id="v-model-select" class="menuBar_Category">
                    <select v-model="selected">
                        <option value="0">Please Select Category</option>
                        <option value="1">Document 1</option>
                        <option value="1">Document 2</option>
                        <option value="1">Document 3</option>
                        <option value="1">Document 4</option>
                        <option value="1">Document 5</option>
                        <option value="1">Document 6</option>
                    </select>
                </div>

                <ul class="Docs_dropdown">
                    <li><router-link to="#">Menu</router-link></li>
                    <li><router-link to="/Project">Project</router-link></li>
                    <li><router-link to="/Docs">Document</router-link></li>
                    <li><router-link to="/#">Menu</router-link></li>
                    <li><router-link to="#">Menu</router-link></li>
                </ul>

            </div>
        </div>
    </header>


    <div id="Transmittals_Main">
        <div class="Transmittals_Div">

            <!-- popup  -->
            <div v-show="is_show" class="Transmittals_popup_Div">
                <div class="Transmittals_popup_Title_Div">
                    <h2>Add Files</h2>
                    <button @click="handle_toggle" type="button" class="closePopup"></button>
                </div>

                <div class="Transmittals_list_Div">
                    <h4>221024_SAMWOO_PROJECT</h4>
                    <ul>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                    </ul>

                    <div class="Transmittals_popup_Bottom">
                        <p>No files selected</p>

                        <div class="Transmittals_popup_Bottom_Btns">
                            <button @click="handle_toggle" type="button" class="Transmittals_CancelBtn">Cancel</button>
                            <button @click="handle_toggle" type="button" class="Transmittals_CreateBtn">Create</button>
                        </div>
                    </div>

                </div>
                <!-- popup  -->
            </div>


            <div class="Transmittals_Category_Div">
                <h4>Documents</h4>
                <ul>
                    <li><router-link to="/docs">Folders</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reviews">Reviews</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/transmittals">Transmittals</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/issues">Issues</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reports">Reports</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                </ul>


            </div>

            <div class="Transmittals_List_Div">
                <div class="Transmittals_List_Title">
                    <h2>Transmittals</h2>

                    <ul>
                        <li>Transmittals</li>
                    </ul>
                </div>
            </div>

            <div class="Transmittals_folders_List_Div">

                <div class="Transmittals_File_search_Div">
                    <input v-model="text" placeholder="search by transmittal name or ID" />
                </div>

                <!-- #1 : Button trigger modal -->
                <button @click="handle_toggle" type="button" class="createTransmittal_Btn">Create Transmittal</button>





                <table class="Transmittals_folders_Sort">
                    <tr class="">
                        <th>Status</th>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Sent by</th>
                        <th>Recipients</th>
                        <th>Created on</th>
                        <th>Files</th>
                    </tr>

                    <tr v-for="item in items" :key="item.title">
                        <td><span v-html="item.status"></span></td>
                        <td><span v-html="item.ID"></span></td>
                        <td><span v-html="item.Title"></span></td>
                        <td><span v-html="item.Sentby"></span></td>
                        <td><span v-html="item.Recipients"></span></td>
                        <td><span v-html="item.Createdon"></span></td>
                        <td><span v-html="item.Files"></span></td>
                    </tr>
                </table>
            </div>

        </div>




    </div>
</template>

<style>
    @import "../assets/css/Docs_transmittals.css";
    @import "../assets/css/reset.css";
</style>


<script>
    export default {
        data() {
            return {
                file_name: "Select Files",  // popup -> ���� ���� ��ư �̺�Ʈ
                message: "Folder",  // popup -> ���� ���� ��ư �̺�Ʈ
                selected: '',
                is_show: false, // upload files ��ư Ŭ�� �̺�Ʈ
                openModal: false,    // upload files Ŭ�� �̺�Ʈ -> ����˾�
                items: [    // folders_list_div ����
                    {
                        "status": "Sent",
                        "ID":"#7",
                        "Title": "221024_Project_Files",
                        "Sentby": "Gildong Hong",
                        "Recipients": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Files": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#6",
                        "Title": "221024_Project_Files",
                        "Sentby": "Gildong Hong",
                        "Recipients": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Files": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#5",
                        "Title": "221024_Project_Files",
                        "Sentby": "Gildong Hong",
                        "Recipients": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Files": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#4",
                        "Title": "221024_Project_Files",
                        "Sentby": "Gildong Hong",
                        "Recipients": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Files": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#3",
                        "Title": "221024_Project_Files",
                        "Sentby": "Gildong Hong",
                        "Recipients": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Files": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#2",
                        "Title": "221024_Project_Files",
                        "Sentby": "Gildong Hong",
                        "Recipients": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Files": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#1",
                        "Title": "221024_Project_Files",
                        "Sentby": "Gildong Hong",
                        "Recipients": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Files": "3",
                    },
                   

                ]
            }
        },

        methods: {
            handle_toggle: function () {
                this.is_show = !this.is_show; // create new ��ư Ŭ�� �̺�Ʈ
            },

            handleFileChange(e) {  // popup -> ���� ���� ��ư �̺�Ʈ
                this.file_name = e.target.files[0].name;
            }


        }
    }
</script>
