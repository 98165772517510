
<template>
    <header>
        <div id="Header_Main">
            <div class="Header_navBar">
                <div class="menuBar">
                    <h2>Document Management</h2>
                </div>

                <div id="v-model-select" class="menuBar_Category">
                    <select v-model="selected">
                        <option value="0">Please Select Category</option>
                        <option value="1">Document 1</option>
                        <option value="1">Document 2</option>
                        <option value="1">Document 3</option>
                        <option value="1">Document 4</option>
                        <option value="1">Document 5</option>
                        <option value="1">Document 6</option>
                    </select>
                </div>

                <ul class="Docs_dropdown">
                    <li><router-link to="#">Menu</router-link></li>
                    <li><router-link to="/Project">Project</router-link></li>
                    <li><router-link to="/Docs">Document</router-link></li>
                    <li><router-link to="/#">Menu</router-link></li>
                    <li><router-link to="#">Menu</router-link></li>
                </ul>

            </div>
        </div>
    </header>


    <div id="reviews_Main">
        <div class="reviews_Div">
            <!-- popup  -->
            <div v-show="is_show" class="Reviews_popup_Div">
                <div class="Review_popup_Title_Div">
                    <h2>Add Files</h2>
                    <button @click="handle_toggle" type="button" class="closePopup"></button>
                </div>

                <div class="Review_popup_list">
                    <h4>221024_SAMWOO_PROJECT</h4>
                    <ul>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                        <li><router-link to="#">221024_SAMWOO_Ofiice_Floor_Plan.dwg</router-link></li>
                    </ul>

                    <div class="Review_popup_Bottom">
                        <p>No files selected</p>

                        <div class="Review_popup_Bottom_Btns">
                            <button @click="handle_toggle" type="button" class="Review_CancelBtn">Cancel</button>
                            <button @click="handle_toggle" type="button" class="Review_CreateBtn">Create</button>
                        </div>
                    </div>

                </div>
                <!-- popup  -->

            </div>
            <div class="reviews_Category_Div">
                <h4>Documents</h4>
                <ul>
                    <li><router-link to="/docs">Folders</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reviews">Reviews</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/transmittals">Transmittals</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/issues">Issues</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reports">Reports</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                </ul>


            </div>

            <div class="reviews_List_Div">
                <div class="reviews_List_Title">
                    <h2>Files</h2>

                    <ul>
                        <li>Folders</li>
                        <li>Sets</li>
                    </ul>
                </div>
            </div>

            <div class="reviews_folders_List_Div">

                <div class="reviews_File_search_Div">
                    <input v-model="text" placeholder="search by review name or ID" />
                </div>

                <!-- #1 : Button trigger modal -->
                <button @click="handle_toggle" type="button" class="createReview_Btn">Create Review</button>





                <table class="reviews_folders_Sort">
                    <tr class="">
                        <th>Status</th>
                        <th>Review Name</th>
                        <th>Workflow</th>
                        <th>Initiated by</th>
                        <th>Next action due</th>
                        <th>Created on</th>
                        <th>Finished on</th>
                        <th>Files</th>
                    </tr>

                    <tr v-for="item in items" :key="item.title">
                        <td><span v-html="item.status"></span></td>
                        <td><span v-html="item.reviewname"></span></td>
                        <td><span v-html="item.workflow"></span></td>
                        <td><span v-html="item.Initiated"></span></td>
                        <td><span v-html="item.Nextactiondue"></span></td>
                        <td><span v-html="item.Createdon"></span></td>
                        <td><span v-html="item.Finishedon"></span></td>
                        <td><span v-html="item.files"></span></td>
                    </tr>
                </table>
            </div>

        </div>

        

        
    </div>
</template>

<style>
    @import "../assets/css/Docs_reviews.css";
    @import "../assets/css/reset.css";
</style>


<script>
    export default {
        data() {
            return {
                file_name: "Select Files",  // popup -> ���� ���� ��ư �̺�Ʈ
                message: "Folder",  // popup -> ���� ���� ��ư �̺�Ʈ
                selected: '',
                is_show: false, // upload files ��ư Ŭ�� �̺�Ʈ
                openModal: false,    // upload files Ŭ�� �̺�Ʈ -> ����˾�
                items: [    // folders_list_div ����
                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Closed",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Closed",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Closed",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Closed",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    {
                        "status": "Open",
                        "reviewname": "2022_Floor_Plan_view",
                        "workflow": "/CAD Files/Project Files1",
                        "Initiated": "Gildong Hong",
                        "nextactiondue": "Gildong Hong",
                        "Createdon": "10/18/2022 18:05",
                        "Finishedon": "--",
                        "files": "2",
                    },

                    
                ]
            }
        },

        methods: {
            handle_toggle: function () {
                this.is_show = !this.is_show; // create new ��ư Ŭ�� �̺�Ʈ
            },

            handleFileChange(e) {  // popup -> ���� ���� ��ư �̺�Ʈ
                this.file_name = e.target.files[0].name;
            }


        }
    }
</script>
