import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'


createApp(App).use(router).mount('#app')

//axios �⺻ ����
axios.defaults.baseURL = '/'
axios.defaults.headers.common.Accept = 'application/json'
axios.interceptors.response.use(

)