
<template>
    <header>
        <div id="Header_Main">
            <div class="Header_navBar">
                <div class="menuBar">
                    <h2>Project Admin</h2>
                </div>

                <div id="v-model-select" class="menuBar_Category">
                    <select v-model="selected">
                        <option value="0">Please Select Category</option>
                        <option value="1">Document 1</option>
                        <option value="1">Document 2</option>
                        <option value="1">Document 3</option>
                        <option value="1">Document 4</option>
                        <option value="1">Document 5</option>
                        <option value="1">Document 6</option>
                    </select>
                </div>

                <ul class="Docs_dropdown">
                    <li><router-link to="#">Menu</router-link></li>
                    <li><router-link to="/Project">Project</router-link></li>
                    <li><router-link to="/Docs">Document</router-link></li>
                    <li><router-link to="/#">Menu</router-link></li>
                    <li><router-link to="#">Menu</router-link></li>
                </ul>

            </div>
        </div>
    </header>


    <div id="Members_Main">

        <div class="Members_Div">
            <div class="Members_Category_Div">
                <h4>Projects</h4>
                <ul>
                    <li><router-link to="#">Overview</router-link></li>
                    <li><router-link to="#">Issues</router-link></li>
                    <li><router-link to="#">Notifications</router-link></li>
                    <li><router-link to="#">Location</router-link></li>
                </ul>

                <h4 class="project_admin_title">Project Admin</h4>
                <ul class="project_admin">
                    <li><router-link to="/project/members">Members</router-link></li>
                    <li><router-link to="#">Companies</router-link></li>
                    <li><router-link to="#">Services</router-link></li>
                    <li><router-link to="#">Profile</router-link></li>

                </ul>


            </div>

            <div class="Members_List_Div">
                <div class="Members_List_Title">
                    <h2>Members</h2>
                </div>

                <div class="Members_folders_List_Div">

                    <div class="Members_File_search_Div">
                        <input v-model="text" placeholder="search members by name or email" />
                    </div>

                    <!-- #1 : Button trigger modal -->
                    <button @click="handle_toggle" type="button" class="AddMembers_Btn">Add Members</button>


                    <div v-show="is_show" class="Members_popup_Div">
                        <div class="Members_popup_Title_Div">
                            <h2>Add project members</h2>
                            <button @click="handle_toggle" type="button" class="closePopup"></button>
                        </div>

                        <div class="Members_info">
                            <p>Title {{ text }}</p>
                            <textarea>person@company.com</textarea>

                            <p>Company {{ text }}</p>
                            <select v-model="selected">
                                <option>Assign from Account Admin</option>
                                <option>Assign from Account Admin</option>
                            </select>

                            <p>Role {{ text }}</p>
                            <select v-model="selected">
                                <option>Assign from Account Admin</option>
                                <option>Assign from Account Admin</option>
                            </select>

                            <div class="Members_project_member_radio">
                                <input type="radio" v-model="picked" />
                                <label for="">Project member</label>
                                <p>Has view access to all tools, but specific permissions vary by role. {{ text }}</p>

                                
                                <input type="radio" v-model="picked" />
                                <label for="">Project administrator</label>
                                <p>Has administrative permissions and can create new projects. {{ text }}</p>
                            </div>

                            <h4>Invite to these products</h4>
                            <span>
                                <strong>Members must also have a subscription to the selected products.</strong>
                                You can provide a subscription in Account Administration or the member may bring their own subscription.
                            </span>


                            <div class="Members_project_member_checklist">
                                <label for="check">
                                    <input id="check" type="checkbox" :value="checked">Docs (current product){{ label }}
                                </label>

                                <label for="check">
                                    <input id="check" type="checkbox" :value="checked">Design Collaboration{{ label }}
                                </label>

                                <label for="check">
                                    <input id="check" type="checkbox" :value="checked">Model Coordination{{ label }}
                                </label>

                                <label for="check">
                                    <input id="check" type="checkbox" :value="checked">Takeoff{{ label }}
                                </label>

                                <label for="check">
                                    <input id="check" type="checkbox" :value="checked">Build{{ label }}
                                </label>

                                <label for="check">
                                    <input id="check" type="checkbox" :value="checked">Cost Management{{ label }}
                                </label>

                            </div>
                        </div>
                    </div>

                    <table class="Members_folders_Sort">
                        <tr class="">
                            <th>Name</th>
                            <th>E-mail</th>
                            <th>Company</th>
                            <th>Role</th>
                            <th>Access Level</th>
                            <th>Phone</th>
                            <th>Status</th>
                            <th>Added on</th>
                        </tr>

                        <tr v-for="item in items" :key="item.title">
                            <td><span v-html="item.name"></span></td>
                            <td><span v-html="item.Email"></span></td>
                            <td><span v-html="item.Company"></span></td>
                            <td><span v-html="item.Role"></span></td>
                            <td><span v-html="item.AccessLevel"></span></td>
                            <td><span v-html="item.Phone"></span></td>
                            <td><span v-html="item.MemberStatus"></span></td>
                            <td><span v-html="item.Addedon"></span></td>
                        </tr>
                    </table>
                </div>
            </div>

            
        </div>
    </div>
</template>

<style>
    @import "../assets/css/ProjectAdmin_members.css";
    @import "../assets/css/reset.css";
</style>


<script>
    export default {
        data() {
            return {
                file_name: "Select Files",  // popup -> ���� ���� ��ư �̺�Ʈ
                message: "Folder",  // popup -> ���� ���� ��ư �̺�Ʈ
                selected: '',
                is_show: false, // upload files ��ư Ŭ�� �̺�Ʈ
                openModal: false,    // upload files Ŭ�� �̺�Ʈ -> ����˾�
                items: [    // folders_list_div ����
                    {
                        "name":"Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },

                    {
                        "name": "Gildong Hong",
                        "Email": "gdhong@bimpeers.com",
                        "Company": "BIMPeers",
                        "Role": "",
                        "AccessLevel": "Project Admin",
                        "Phone": "010-1234-5678",
                        "MemberStatus": "Active",
                        "Addedon": "2022.10.24",
                    },
                   
                ]
            }
        },

        methods: {
            handle_toggle: function () {
                this.is_show = !this.is_show; // create new ��ư Ŭ�� �̺�Ʈ
            },

            handleFileChange(e) {  // popup -> ���� ���� ��ư �̺�Ʈ
                this.file_name = e.target.files[0].name;
            }


        }
    }
</script>
