
<template>
    <header>
        <div id="Header_Main">
            <div class="Header_navBar">
                <div class="menuBar">
                    <h2>Project Management</h2>
                </div>

                <div id="v-model-select" class="menuBar_Category">
                    <select v-model="selected">
                        <option value="0">Please Select Category</option>
                        <option value="1">Category 1</option>
                        <option value="1">Category 2</option>
                        <option value="1">Category 3</option>
                        <option value="1">Category 4</option>
                        <option value="1">Category 5</option>
                        <option value="1">Category 6</option>
                    </select>
                </div>

                <ul class="dropdown">
                    <li><router-link to="#">Menu</router-link></li>
                    <li><router-link to="/Project">Project</router-link></li>
                    <li><router-link to="/Docs">Document</router-link></li>
                    <li><router-link to="/#">Menu</router-link></li>
                    <li><router-link to="#">Menu</router-link></li>
                </ul>

            </div>
        </div>
    </header>


    <div id="Management_Main">
        <div class="Main_Div">
            <div class="Management_Div">
                <div class="Category_Div">

                    <h4>Projects</h4>
                    <ul>
                        <li><router-link to="#">Overview</router-link></li>
                        <li><router-link to="#">Issues</router-link></li>
                        <li><router-link to="#">Notifications</router-link></li>
                        <li><router-link to="#">Location</router-link></li>

                    </ul>

                    <h4 class="project_admin_title">Project Admin</h4>
                    <ul class="project_admin">
                        <li><router-link to="/project/members">Members</router-link></li>
                        <li><router-link to="#">Companies</router-link></li>
                        <li><router-link to="#">Services</router-link></li>
                        <li><router-link to="#">Profile</router-link></li>

                    </ul>
                </div>
            </div>

            <div class="ManagementList_Div">
                <!-- #1 : Button trigger modal -->
                <button @click="handle_toggle" type="button" class="createNew_Btn">Create New</button>

                <div v-show="is_show" class="Account_Div">
                    <div class="project_Title_Div">
                        <h2>Create Project</h2>
                        <button @click="handle_toggle" type="button" class="closeBtn"></button>
                    </div>

                    <div class="project_info">
                        <p>Project Name {{ text }}</p>
                        <input v-model="text" />

                        <p>Project Type {{ text }}</p>
                        <input v-model="text" />

                        <p>Construction Type {{ text }}</p>
                        <input v-model="text" />

                        <p>Project Value {{ text }}</p>
                        <input v-model="text" />

                        <p>Project Data Location {{ text }}</p>
                        <p class="add"></p>
                        <select v-model="selected">
                            <option disabled value="">Please select Data Location</option>
                            <option>CAD Files</option>
                            <option>InfraWorks Files</option>
                            <option>PDF Files</option>
                            <option>Document Files</option>
                        </select>

                        <div class="project_info_table">
                            <div class="project_info_half">
                                <p>Project Start Date {{ text }}</p>
                                <input v-model="text" />
                            </div>

                            <div class="project_info_half">
                                <p>Project End Date {{ text }}</p>
                                <input v-model="text" />
                            </div>
                        </div>

                    </div>

                    <div class="project_info_detail">
                        <p>Project Address {{ text }}</p>
                        <input v-model="text" placeholder="Address Line" />
                        <div class="project_city_Div">
                            <div class="project_city_table">
                                <div class="project_city">
                                    <p>City {{ text }}</p>
                                    <select v-model="selected">
                                        <option disabled value="">Please select city</option>
                                        <option>city name 1</option>
                                        <option>city name 2</option>
                                        <option>city name 3</option>
                                        <option>city name 4</option>
                                        <option>city name 5</option>
                                    </select>
                                </div>

                                <div class="project_city">
                                    <p>State {{ text }}</p>
                                    <select v-model="selected">
                                        <option disabled value="">Please select state</option>
                                        <option>State name 1</option>
                                        <option>State name 2</option>
                                        <option>State name 3</option>
                                        <option>State name 4</option>
                                        <option>State name 5</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <p>Project Estimate {{ text }}</p>
                        <input v-model="text" placeholder="Address Line" />

                        <p>Client Name{{ text }}</p>
                        <input v-model="text" placeholder="Please type Client Name" />
                        <button @click="closePopup" class="makeBtn">Create</button>
                    </div>
                </div>


                <div class="search_Div">
                    <input v-model="text" placeholder="search by project name or client company" />
                </div>

                <table class="ManagementList_Sort">
                    <tr class="">
                        <th>Project Status</th>
                        <th>Project Name</th>
                        <th>Type</th>
                        <th>Project Duration</th>
                        <th>Location</th>
                        <th>Members</th>
                        <th>Client Company</th>
                    </tr>

                    <tr v-for="item in items" :key="item.title">
                        <td><span v-html="item.status"></span></td>
                        <td><span v-html="item.name"></span></td>
                        <td><span v-html="item.type"></span></td>
                        <td><span v-html="item.duration"></span></td>
                        <td><span v-html="item.location"></span></td>
                        <td><span v-html="item.members"></span></td>
                        <td><span v-html="item.client"></span></td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</template>

<style>
    @import "../assets/css/Project.css";
    @import "../assets/css/reset.css";
</style>


<script>
    export default {
        data() {
            return {
                selected: '',
                is_show: false, // create new ��ư Ŭ�� �̺�Ʈ
                openModal: false,    // ����˾�
                Category_List: [    // ManagementList_Sort Table ����
                    {
                        "text": "Category 1",
                        "value":"0",
                    },

                    {
                        "text": "Category 2",
                        "value": "1",
                    },

                    {
                        "text": "Category 3",
                        "value": "2",
                    },

                    {
                        "text": "Category 4",
                        "value": "3",
                    },

                    {
                        "text": "Category 5",
                        "value": "4",
                    },

                   
                ],

                items: [    // ManagementList_Sort Table ����
                    {
                        "status": "Ongoing",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Ulsan",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Ongoing",
                        "name": "2022_Building_Project",
                        "type": "Coordination",
                        "duration": "2022.01 ~ 2023.11",
                        "location": "Seoul",
                        "members": "10",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Ongoing",
                        "name": "2022_Office_Project",
                        "type": "Commissioning",
                        "duration": "2022.01 ~ 2022.12",
                        "location": "Jeju",
                        "members": "3",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Ongoing",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Busan",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Finished",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Ansan",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Finished",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Changwon",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Finished",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Incheon",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Finished",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Chungju",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },

                    {
                        "status": "Ongoing",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Kangreung",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },
                    {
                        "status": "Ongoing",
                        "name": "2022_Office_Project",
                        "type": "Design",
                        "duration": "2022.04 ~ 2024.02",
                        "location": "Jinju",
                        "members": "5",
                        "client": "SAMSUNG Engineering",
                    },

                ]
            }
        },

        methods: {
            handle_toggle: function () {
                this.is_show = !this.is_show; // create new ��ư Ŭ�� �̺�Ʈ
            }
        }
    };
</script>
