
<template>
    <div id="Main">
        <div class="Login_Main_Div">
            <div class="Login_Div">
                <img src="../assets/images/logo/logo.png" />
                <form action="">
                    <div>
                        <h4>USER ID</h4>
                        <label for="username"></label>
                        <input id="username" type="text">
                    </div>

                    <div>
                        <h4>PASSWORD</h4>
                        <label for="password"></label>
                        <input id="password" type="password">
                    </div>

                    <button class="Login_Btn"><router-link to="/Project">Login</router-link></button>

                    <div class="checkboxDiv">
                        <label for="checkbox"></label>
                        <input id="checkbox" type="checkbox"><p>Stay LogIn</p>
                        <label for="checkbox"></label>
                        <input id="checkbox" type="checkbox"><p>ID & Password</p>
                    </div>
                </form>
            </div>

           
        </div>
    </div>
</template>

<style>
    @import "../assets/css/Login.css";
</style>


