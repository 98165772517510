
<template>
    <header>
        <div id="Header_Main">
            <div class="Header_navBar">
                <div class="menuBar">
                    <h2>Document Management</h2>
                </div>

                <div id="v-model-select" class="menuBar_Category">
                    <select v-model="selected">
                        <option value="0">Please Select Category</option>
                        <option value="1">Document 1</option>
                        <option value="1">Document 2</option>
                        <option value="1">Document 3</option>
                        <option value="1">Document 4</option>
                        <option value="1">Document 5</option>
                        <option value="1">Document 6</option>
                    </select>
                </div>

                <ul class="Docs_dropdown">
                    <li><router-link to="#">Menu</router-link></li>
                    <li><router-link to="/Project">Project</router-link></li>
                    <li><router-link to="/Docs">Document</router-link></li>
                    <li><router-link to="/#">Menu</router-link></li>
                    <li><router-link to="#">Menu</router-link></li>
                </ul>

            </div>
        </div>
    </header>


    <div id="Issues_Main">

        <div class="Issues_Div">

            <div v-show="is_show" class="Issues_popup_Div">
                <div class="Issues_popup_Title_Div">
                    <h2>Create Issue</h2>
                    <button @click="handle_toggle" type="button" class="closePopup"></button>
                </div>

                <div class="Issues_info">
                    <p>Title {{ text }}</p>
                    <input v-model="text" />

                    <p>Status {{ text }}</p>
                    <input v-model="text" />

                    <p>Type {{ text }}</p>
                    <select v-model="selected">
                        <option disabled value="">Please select Data Location</option>
                        <option>Commissioning</option>
                        <option>Coordination > Clash</option>
                        <option>Coordination > Coordination</option>
                        <option>Document Files</option>
                    </select>

                    <p>Description {{ text }}</p>
                    <textarea v-model="message">Desribe the issue</textarea>

                    <p>Assigned to {{ text }}</p>
                    <select v-model="selected">
                        <option disabled value="">Select a member, role or company</option>
                        <option>Architect</option>
                        <option>Architect</option>
                    </select>


                    <p>Watchers{{ text }}</p>
                    <select v-model="selected">
                        <option disabled value="">Select Watchers</option>
                        <option>Gildong Hong</option>
                    </select>


                    <p>Location {{ text }}</p>
                    <select v-model="selected">
                        <option disabled value="">Select a location</option>
                        <option></option>
                    </select>

                    <p>Location Details {{ text }}</p>
                    <input v-model="text" />

                    <p>Due date {{ text }}</p>
                    <input v-model="text" />

                    <p>Start date {{ text }}</p>
                    <input v-model="text" />

                    <p>Placement {{ text }}</p>
                    <select v-model="selected">
                        <option disabled value="">Select a location</option>
                        <option>Files</option>
                        <option>Sheets</option>
                    </select>

                    <p>Root cause {{ text }}</p>
                    <select v-model="selected">
                        <option disabled value="">Select a root cause</option>
                        <option>Files</option>
                        <option>Sheets</option>
                    </select>

                </div>
            </div>


            <div class="Issues_Category_Div">
                <h4>Documents</h4>
                <ul>
                    <li><router-link to="/docs">Folders</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reviews">Reviews</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/transmittals">Transmittals</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/issues">Issues</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reports">Reports</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                </ul>


            </div>

            <div class="Issues_List_Div">
                <div class="Issues_List_Title">
                    <h2>Issues</h2>

                    <ul>
                        <li>Issues</li>
                    </ul>
                </div>

                <div class="Issues_folders_List_Div">

                    <div class="Issues_File_search_Div">
                        <input v-model="text" placeholder="search by issue title or ID" />
                    </div>

                    <!-- #1 : Button trigger modal -->
                    <button @click="handle_toggle" type="button" class="createIssues_Btn">Create Issue</button>




                    <table class="Issues_folders_Sort">
                        <tr class="">
                            <th>ID</th>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Assigned to</th>
                            <th>Due Date</th>
                            <th>Start Date</th>
                            <th>Placement</th>
                        </tr>

                        <tr v-for="item in items" :key="item.title">
                            <td><span v-html="item.ID"></span></td>
                            <td><span v-html="item.Title"></span></td>
                            <td><span v-html="item.Status"></span></td>
                            <td><span v-html="item.Type"></span></td>
                            <td><span v-html="item.Assignedto"></span></td>
                            <td><span v-html="item.DueDate"></span></td>
                            <td><span v-html="item.StartDate"></span></td>
                            <td><span v-html="item.Placement"></span></td>
                        </tr>
                    </table>
                </div>
            </div>


        </div>
    </div>
</template>

<style>
    @import "../assets/css/Docs_issues.css";
    @import "../assets/css/reset.css";
</style>


<script>
    export default {
        data() {
            return {
                file_name: "Select Files",  // popup -> ���� ���� ��ư �̺�Ʈ
                message: "Folder",  // popup -> ���� ���� ��ư �̺�Ʈ
                selected: '',
                is_show: false, // upload files ��ư Ŭ�� �̺�Ʈ
                openModal: false,    // upload files Ŭ�� �̺�Ʈ -> ����˾�
                items: [    // folders_list_div ����
                    {
                        "ID":"#7",
                        "Title": "General",
                        "Status": "Open",
                        "Type": "Gildong Hong",
                        "Assignedto": "Architect",
                        "DueDate": "2022.10.24",
                        "StartDate": "2022.10.25",
                        "Placement": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#6",
                        "Title": "General",
                        "Status": "Open",
                        "Type": "Gildong Hong",
                        "Assignedto": "Architect",
                        "DueDate": "2022.10.24",
                        "StartDate": "2022.10.25",
                        "Placement": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#5",
                        "Title": "General",
                        "Status": "Open",
                        "Type": "Gildong Hong",
                        "Assignedto": "Architect",
                        "DueDate": "2022.10.24",
                        "StartDate": "2022.10.25",
                        "Placement": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#4",
                        "Title": "General",
                        "Status": "Open",
                        "Type": "Gildong Hong",
                        "Assignedto": "Architect",
                        "DueDate": "2022.10.24",
                        "StartDate": "2022.10.25",
                        "Placement": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#3",
                        "Title": "General",
                        "Status": "Open",
                        "Type": "Gildong Hong",
                        "Assignedto": "Architect",
                        "DueDate": "2022.10.24",
                        "StartDate": "2022.10.25",
                        "Placement": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#2",
                        "Title": "General",
                        "Status": "Open",
                        "Type": "Gildong Hong",
                        "Assignedto": "Architect",
                        "DueDate": "2022.10.24",
                        "StartDate": "2022.10.25",
                        "Placement": "3",
                    },

                    {
                        "status": "Sent",
                        "ID": "#1",
                        "Title": "General",
                        "Status": "Open",
                        "Type": "Gildong Hong",
                        "Assignedto": "Architect",
                        "DueDate": "2022.10.24",
                        "StartDate": "2022.10.25",
                        "Placement": "3",
                    },
                   
                ]
            }
        },

        methods: {
            handle_toggle: function () {
                this.is_show = !this.is_show; // create new ��ư Ŭ�� �̺�Ʈ
            },

            handleFileChange(e) {  // popup -> ���� ���� ��ư �̺�Ʈ
                this.file_name = e.target.files[0].name;
            }
        }
    }
</script>
