
<template>
    <header>
        <div id="Header_Main">
            <div class="Header_navBar">
                <div class="menuBar">
                    <h2>Document Management</h2>
                </div>

                <div id="v-model-select" class="menuBar_Category">
                    <select v-model="selected">
                        <option value="0">Please Select Category</option>
                        <option value="1">Document 1</option>
                        <option value="1">Document 2</option>
                        <option value="1">Document 3</option>
                        <option value="1">Document 4</option>
                        <option value="1">Document 5</option>
                        <option value="1">Document 6</option>
                    </select>
                </div>

                <ul class="Docs_dropdown">
                    <li><router-link to="#">Menu</router-link></li>
                    <li><router-link to="/Project">Project</router-link></li>
                    <li><router-link to="/Docs">Document</router-link></li>
                    <li><router-link to="/#">Menu</router-link></li>
                    <li><router-link to="#">Menu</router-link></li>
                </ul>

            </div>
        </div>
    </header>


    <div id="Docs_Main">
        <div class="Docs_Div">
            
            <!-- popup  -->
            
            <div v-show="is_show" class="Docs_folders_popup_Div">
                <div class="Docs_popup_Title_Div">
                    <h2>Upload Files</h2>
                    <button @click="handle_toggle" type="button" class="closePopup"></button>
                </div>

                <div class="custom-file">
                    <input id="customFile" type="file" @change="handleFileChange" />
                </div>

                <div class="drag_upload">
                    <div class="drag_upload_icon"></div>
                    <p>Drag files here or choose an option above</p>
                </div>
            </div>
            <!-- popup  -->

            <div class="Docs_Category_Div">

                <h4>Documents</h4>
                <ul>
                    <li><router-link to="/docs">Folders</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reviews">Reviews</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/transmittals">Transmittals</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/issues">Issues</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                    <li><router-link to="/docs/reports">Reports</router-link></li>
                    <li><button @click="folder_open" type="button" class="creatFolder_Btn"></button></li>

                </ul>


            </div>

            <div class="Docs_List_Div">
                <div class="Docs_List_Title">
                    <h2>Folders</h2>
                    <ul>
                        <li>Files</li>
                        <li>Sets</li>
                    </ul>
                </div>

                <div class="Docs_Folder_Category">

                    <h4>Folders</h4>
                    <ul>
                        <li><router-link to="#">Project Folder 01</router-link></li>
                        <li><router-link to="#">Project Folder 02</router-link></li>
                        <li><router-link to="#">Project Folder 03</router-link></li>
                        <li><router-link to="#">Project Folder 04</router-link></li>
                        <li><router-link to="#">Project Folder 05</router-link></li>

                    </ul>
                </div>
            </div>


            <div class="Docs_folders_List_Div">
                <!-- #1 : Button trigger modal -->
                <button @click="handle_toggle" type="button" class="uploadFiles_Btn">Upload Files</button>
                <div class="File_search_Div">
                    <input v-model="text" placeholder="search for documents" />
                </div>

                <table class="Docs_folders_Sort">
                    <tr class="">
                        <th>Name</th>
                        <th>Set</th>
                        <th>Location</th>
                        <th>Version</th>
                        <th>Size</th>
                        <th>Last Updated</th>
                        <th>Updated by</th>
                    </tr>

                    <tr v-for="item in items" :key="item.title">
                        <td><span v-html="item.name"></span></td>
                        <td><span v-html="item.set"></span></td>
                        <td><span v-html="item.location"></span></td>
                        <td><span v-html="item.version"></span></td>
                        <td><span v-html="item.size"></span></td>
                        <td><span v-html="item.updated"></span></td>
                        <td><span v-html="item.updatedby"></span></td>
                    </tr>
                </table>
            </div>

        </div>

        

        
    </div>
</template>

<style>
    @import "../assets/css/Docs.css";
    @import "../assets/css/reset.css";
</style>


<script>
    export default {
        data() {
            return {
                file_name: "Select Files",  // popup -> ���� ���� ��ư �̺�Ʈ
                message: "Folder",  // popup -> ���� ���� ��ư �̺�Ʈ
                selected: '',
                is_show: false, // upload files ��ư Ŭ�� �̺�Ʈ
                openModal: false,    // upload files Ŭ�� �̺�Ʈ -> ����˾�
                items: [    // folders_list_div ����
                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },

                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },

                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },

                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },

                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },

                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },

                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },

                    {
                        "name": "Project Files 1",
                        "set": "Floor_plan",
                        "location": "/CAD Files/Project Files1",
                        "version": "V1.0.0",
                        "size": "62kb",
                        "updated": "10/18/2022",
                        "updatedby": "Gildong,Hong",
                    },
                ]
            }
        },

        methods: {
            handle_toggle: function () {
                this.is_show = !this.is_show; // create new ��ư Ŭ�� �̺�Ʈ
            },

            handleFileChange(e) {  // popup -> ���� ���� ��ư �̺�Ʈ
                this.file_name = e.target.files[0].name;
            }
        }
    };
</script>
