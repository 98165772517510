import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Project from '../views/Project.vue'
import Docs from '../views/Docs.vue'
import Docs_reviews from '../views/Docs_reviews.vue'
import Docs_transmittals from '../views/Docs_transmittals.vue'
import Docs_issues from '../views/Docs_issues.vue'
import Docs_reports from '../views/Docs_reports.vue'


import ProjectAdmin_members from '../views/ProjectAdmin_members.vue';
const routes = [

    { path: '/', redirect: '/login' },



    {
        path: '/login',
        name: 'login',
        component:Login
    },
    {
        path: '/project',
        name: 'project',
        component: Project
    },

    {
        path: '/docs',
        name: 'docs',
        component: Docs
    },

    
    {
        path: '/docs/reviews',
        name: 'docs_reviews',
        component: Docs_reviews
    },

    {
        path: '/docs/transmittals',
        name: 'docs_transmittals',
        component: Docs_transmittals
    },


    {
        path: '/docs/issues',
        name: 'docs_issues',
        component: Docs_issues
    },

    {
        path: '/docs/reports',
        name: 'docs_reports',
        component: Docs_reports
    },

    {
        path: '/project/members',
        name: 'ProjectAdmin_members',
        component: ProjectAdmin_members
    },



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
